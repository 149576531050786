<!-- 入库单管理-查看详情 -->

<template>
  <div class="enterListDetail">
    <back />
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="selectValue" placeholder="全部状态" @change="handleSelect" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="barCode" placeholder="搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productNameSearch" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%;" :cell-style="reviewRowClass">
          <el-table-column min-width="120" prop="odd" label="入库单号"></el-table-column>
          <!-- <el-table-column min-width="120" prop="order" label="商品编号"></el-table-column> -->
          <el-table-column min-width="120" prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" prop="name" label="商品名称"></el-table-column>
          <el-table-column min-width="120" prop="supermarket" label="厂家" width="150"></el-table-column>
          <el-table-column min-width="120" prop="category" label="商品类目" width="100"></el-table-column>
          <el-table-column min-width="120" prop="scale" label="商品规格" width="100"></el-table-column>
          <el-table-column min-width="120" prop="cost" label="成本价" width="100"></el-table-column>
          <el-table-column min-width="120" prop="num" label="商品数量" width="100"></el-table-column>
          <el-table-column min-width="120" prop="total" label="总金额" width="100"></el-table-column>
          <el-table-column min-width="120" prop="status" label="状态" width="130"></el-table-column>
          <el-table-column min-width="120" prop="desc" label="描述" width="200" header-align="center"></el-table-column>
          <el-table-column label="修改" header-align="center" align="center" min-width="120" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="handleModify(scope.row)">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <!-- 修改弹窗 -->
    <el-dialog title="修改" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品条形码" prop="barCode">
              <el-input v-model="ruleForm.barCode" placeholder="请输入" readonly></el-input>
            </el-form-item>
            <el-form-item label="商品类目" prop="category">
              <el-select v-model="ruleForm.category" filterable placeholder="请选择/请输入">
                <el-option v-for="item in dialogOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="成本价" prop="price">
              <el-input v-model="ruleForm.price" placeholder="请输入" readonly></el-input>
            </el-form-item>
            <el-form-item label="总金额" prop="total">
              <el-input v-model="ruleForm.total" placeholder="请输入" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入" readonly></el-input>
            </el-form-item>
            <el-form-item label="商品规格" prop="scale">
              <el-input v-model="ruleForm.scale" placeholder="请输入" readonly></el-input>
            </el-form-item>
            <el-form-item label="商品数量" prop="number">
              <el-input v-model="ruleForm.number" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 下拉框数据(全部状态)
      selectValue: '',
      options: [{
        value: '0',
        label: '入库成功'
      }, {
        value: '1',
        label: '入库失败'
      },],
      productCodeSearch: '', // 搜索商品编号
      barCode: '', // 搜索商品条形码
      productNameSearch: '', // 搜索商品名称
      // 表格数据
      tableData: [

      ],
      // 弹窗数据
      dialogVisible: false, // 弹窗显隐
      ruleForm: {
        barCode: '',  // 商品条形码
        category: '',  // 商品类目
        price: '',  // 成本价
        total: '',  // 总金额
        name: '',  // 商品名称
        scale: '',  // 商品规格
        number: '',  // 商品数量
      },
      rules: {
        barCode: [{ required: true, message: '请输入商品条形码', trigger: 'blur' }],
        category: [{ required: true, message: '请选择商品类目', trigger: 'blur' }],
        price: [{ required: true, message: '请输入成本价', trigger: 'blur' }],
        total: [{ required: true, message: '请输入总金额', trigger: 'blur' }],
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        scale: [{ required: true, message: '请输入商品规格', trigger: 'blur' }],
        number: [{ required: true, message: '请输入商品数量', trigger: 'blur' }],
      },
      // 弹窗下拉数据列表
      dialogOptions: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      },],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    // this.getProductInfoSupplierList();
  },
  methods: {
    // 获取商品列表
    getProductInfoSupplierList() {
      this.loading = true;
      this.$axios.get(this.$api.getProductInfoSupplierList, {
        params: {
          productName: this.searchValue,
          page: this.currentPage,
          pageSize: this.pageSize,
          auditStatus: this.selectValue,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false;
      })
    },
    // 修改
    handleModify(row) {
      this.dialogVisible = true;
      this.ruleForm.barCode = row.barCode;
      this.ruleForm.category = row.category;
      this.ruleForm.price = row.cost;
      this.ruleForm.total = row.total;
      this.ruleForm.name = row.name;
      this.ruleForm.scale = row.scale;
      this.ruleForm.number = row.num;
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.resetForm();
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$message.success('修改成功');
        } else {
          return false;
        }
        this.handleCloseDialog();
      });
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },
    // 搜索
    onSearch() {
      this.getProductInfoSupplierList();
    },
    // 下拉选择
    handleSelect(value) {
      this.selectValue = value;
      this.getProductInfoSupplierList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.getProductInfoSupplierList();
    },
    // 换页
    handleCurrentChange(e) {
      this.currentPage = e;
      this.getProductInfoSupplierList();
    },
    // 颜色变化
    reviewRowClass({ row, columnIndex }) {
      if (columnIndex == 9 && row.status == '入库失败') {
        return { color: '#EB2500' };
      }
      if (columnIndex == 10 && row.desc == '商品数据与商品库不匹配') {
        return { color: '#EB2500' };
      }
    },

  }

}



</script>

<style scoped lang="scss">
.enterListDetail {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }
  }
}
</style>
